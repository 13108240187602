import { Dispatch, MouseEventHandler, SetStateAction } from "react";

export type Operator =
  | TimeOperator
  | MultiSelectionOperator
  | MetricsOperator
  | BooleanOperator
  | FreeFormSearchOperator;

export interface FilterProps {
  name: StandardFilterType | string;
  value?: string | string[] | boolean;
  operator: Operator | undefined;
  category: FilterCategory;
  handleChange: (
    operator: Operator,
    value: string | string[] | boolean
  ) => void;
  handleRemove: MouseEventHandler<HTMLButtonElement>;
  metric?: Metric;
  options?: OptionsType[];
  operators?: MultiSelectionOperator[];
  readablePluralOpt?: boolean;
  hideTagBorder?: boolean;
}

export interface StandardFilterProps extends FilterProps {
  handlePopupOpen: () => void;
  handlePopupClose: () => void;
  isPopupOpen: boolean;
  setIsFilterFocussed: Dispatch<SetStateAction<boolean>>;
}

export interface Metric {
  name: string;
  type: MetricInputType;
  operators: string[];
  allowed_values?: string[];
}

export interface MetricsFilterProps extends StandardFilterProps {
  metric: Metric;
}

export type FilterCategory = "standard" | "metrics" | "sort";

export interface ParsedFilterItem {
  name: StandardFilterType | string;
  operator: Operator | undefined;
  value?: string | string[] | boolean;
  category: FilterCategory;
}

export enum StandardFilterType {
  TAGS = "tags",
  CREATED_AT = "created_at",
  CLIENT_ENV = "client_env",
  FROM_NUMBER = "from_number",
  DURATION = "duration",
  SORT = "sort",
  OFFSET = "offset",
  LANGUAGE = "language",
  IN_PROGRESS = "in_progress",
  STATUSES = "statuses",
  FLOWS = "flows",
  LAST_MODIFIED = "last_modified",
  REVIEWERS = "reviewers",
  REVIEWED = "reviewed",
  VARIANT = "variant_id",
  CALL_SID = "conversation_id",
  EXTENSION = "extension",
  FALLBACK_ROUTE = "fallback_route",
  FUNCTION_CALLS = "function_calls",
  CALL_HANDOFF = "HANDOFF_REASON",
  SORT_ALPHABETICALLY = "sort_alphabetically",
  SORT_LAST_UPDATED = "sort_last_updated",
  SMS_EVENTS = "sms_events",
  CONTENT_FILTER_TRIGGERED = "content_filters_triggered",
  TO_NUMBER = "to_number",
  TRANSCRIPT = "transcript",
  PLAY_COUNT = "play_count",
  VOICE_ID = "voice_id",
}

export enum ReadableFilterType {
  tags = "Tags",
  created_at = "Start date",
  from_number = "Phone number",
  to_number = "To number",
  client_env = "Environment",
  duration = "Duration",
  sort = "Sort",
  offset = "Offset",
  language = "Language",
  in_progress = "In progress",
  statuses = "Status",
  flows = "Flows",
  last_modified = "Last modified",
  reviewers = "Reviewer",
  reviewed = "Reviewed",
  variant_id = "Variant",
  conversation_id = "Call SID",
  extension = "Inbound route",
  fallback_route = "Fallback route",
  function_calls = "Function call",
  HANDOFF_REASON = "Call handoff",
  sort_alphabetically = "Alphabetically",
  sort_last_updated = "Last updated",
  sms_events = "SMS events",
  transcript = "Transcript",
  play_count = "Play count",
  voice_id = "Voice ID",
  content_filters_triggered = "Safety flag",
}

export enum OrderType {
  ASC = "asc",
  DESC = "desc",
}
export interface SortQuery {
  id: string;
  order: OrderType;
  category: "sort";
}

export enum BooleanOperator {
  eq = "eq",
}

export enum TimeOperator {
  lt = "lt",
  gt = "gt",
  le = "le",
  ge = "ge",
  eq = "eq",
}

export enum MultiSelectionOperator {
  in = "in",
  ex = "ex",
  eq = "eq",
  exists = "exists",
  notExists = "notExists",
  contains = "contains",
  notContains = "not_contains",
}

export enum MetricsOperator {
  eq = "eq",
  lt = "lt",
  gt = "gt",
  le = "le",
  ge = "ge",
  in = "in",
  ex = "ex",
  exists = "exists",
}

export enum FreeFormSearchOperator {
  in = "in",
  eq = "eq",
  notContains = "not_contains",
}

export enum ReadableTimeOp {
  lt = "before",
  gt = "after",
  le = "before or on",
  ge = "after or on",
  eq = "is",
}

export enum ReadableDurationOp {
  lt = "less than",
  gt = "more than",
  le = "less than or equals",
  ge = "more than or equals",
  eq = "is",
}

export enum ReadableOptionOp {
  in = "includes",
  ex = "excludes",
  eq = "is",
  exists = "has any value",
  notExists = "has no value",
  contains = "contains",
  not_contains = "does not contain",
}

export enum ReadablePluralOptionOp {
  in = "include",
  ex = "exclude",
  eq = "are exactly",
  exists = "has any value",
  notExists = "has no value",
  contains = "contains",
  not_contains = "does not contain",
}

export enum ReadableMetricsOperator {
  lt = "is less than",
  gt = "is greater than",
  le = "is less than or equals to",
  ge = "is greater than or equals to",
  eq = "is",
  in = "includes",
  ex = "excludes",
  exists = "exists",
  contains = "contains",
  not_contains = "does not contain",
}

export enum ReadableSearchOperator {
  in = "contains",
  eq = "is",
  not_contains = "does not contain",
}

export enum EnvironmentType {
  SANDBOX = "sandbox",
  LIVE = "live",
  PRE_RELEASE = "pre-release",
}

export enum MetricInputType {
  int = "int",
  float = "float",
  bool = "bool",
  string = "string",
}

export type OptionsType = {
  value: string;
  label: string;
};

export type FilterOptionsType = {
  [key in StandardFilterType]?: OptionsType[];
};

export const LANGUAGE_CODE_MAP: { [key: string]: string } = {
  "en-gb": "English (UK)",
  "en-us": "English (US)",
  "fr-fr": "French",
  "de-de": "German",
  "es-es": "Spanish",
  "it-it": "Italian",
  "nl-nl": "Dutch",
  zh: "Chinese",
  "zh-tw": "Chinese (Traditional)",
  "hr-hr": "Croatian",
  "sr-rs": "Serbian",
  "pl-pl": "Polish",
  "pt-br": "Portuguese (Brazil)",
  "pt-pt": "Portuguese",
  "tr-tr": "Turkish",
  "nl-be": "Flemish",
  "ja-jp": "Japanese",
  "iw-il": "Hebrew",
  "sv-se": "Swedish",
  "ko-kr": "Korean",
};

export const MULTI_SELECTION_OPERATORS = [
  MultiSelectionOperator.eq,
  MultiSelectionOperator.ex,
  MultiSelectionOperator.in,
];

export const MULTI_SELECTION_WITH_EXISTENCE_OPERATORS = [
  MultiSelectionOperator.eq,
  MultiSelectionOperator.ex,
  MultiSelectionOperator.in,
  MultiSelectionOperator.exists,
  MultiSelectionOperator.notExists,
];

export const FUNCTION_CALL_OPERATORS = [
  MultiSelectionOperator.eq,
  MultiSelectionOperator.contains,
  MultiSelectionOperator.notContains,
  MultiSelectionOperator.exists,
  MultiSelectionOperator.notExists,
];

export const CONTENT_FILTER_TRIGGERED_OPERATORS = [
  MultiSelectionOperator.contains,
];

export const FILTER_OPTION_MENU_STYLES: React.CSSProperties = {
  minWidth: "270px",
  width: "fit-content",
  maxHeight: "560px",
  zIndex: 15,
  overflowY: "auto",
};

export const clientEnvOptions = [
  EnvironmentType.LIVE,
  EnvironmentType.SANDBOX,
  EnvironmentType.PRE_RELEASE,
].map((env) => ({ label: env, value: env } as OptionsType));

export const SINGLE_USE_FILTERS = [
  StandardFilterType.SORT_ALPHABETICALLY,
  StandardFilterType.SORT_LAST_UPDATED,
];

export const MUTUALLY_EXCLUSIVE_FILTERS = [
  [
    StandardFilterType.SORT_ALPHABETICALLY,
    StandardFilterType.SORT_LAST_UPDATED,
  ],
];
